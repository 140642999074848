import styled from "@emotion/styled";
import React from "react";

import { Button, IButtonProps } from "../../Button";
import { IListItemProps, ListBoxItem } from "..";

type IListItemButtonProps = IListItemProps & Pick<IButtonProps, "onClick" | "kind">;

export const ListBoxItemButton: React.FC<IListItemButtonProps> = ({
  kind,
  onClick,
  ...otherProps
}) => {
  return (
    <StyledButton kind={kind} onClick={onClick}>
      <ListBoxItem {...otherProps} />
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
  text-align: initial;
`;
