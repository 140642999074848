/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";

import { ListBoxItemButton } from "~src/designSystem/atoms/ListBoxItem/ListBoxItemButton";
import { CA } from "~src/designSystem/icons/flags/1x1/CA";
import { GB } from "~src/designSystem/icons/flags/1x1/GB";
import { US } from "~src/designSystem/icons/flags/1x1/US";
import { Spacer } from "~src/designSystem/layout/Spacer";
import { LoadingSpinner } from "~src/designSystem/loading/LoadingSpinner";
import { t } from "~src/designSystem/theme";
import { useUser } from "~src/shared/auth/useUser";
import { CONNECT_PLAID_SOURCE_TO_PLAID_MODAL_PAGE_EVENT } from "~src/shared/dataSources/tracking/utils";
import { DATA_SOURCE_TEXTS } from "~src/shared/dataSources/utils";
import { useStepper } from "~src/shared/stepper/stepperContext";
import { useAnalytics } from "~src/shared/thirdParties/segment";
import { IConnectDataSourceFlowSource } from "~src/shared/types";

import { ContactSupport } from "../../components/ContactSupport";
import { DataSourceLayout } from "../../components/dataSourceLayout";
import { DataSourceLayoutButtonSection } from "../../components/dataSourceLayout/DataSourceLayoutButtonSection";
import { PlaidEnabledCountryCode } from "../hooks/types";

type IOption = {
  code: PlaidEnabledCountryCode;
  name: string;
  icon: React.FC;
};

export const SupportedOptions = [
  {
    code: "US",
    name: "United States",
    icon: US,
  },
  {
    code: "CA",
    name: "Canada",
    icon: CA,
  },
  {
    code: "GB",
    name: "United Kingdom",
    icon: GB,
  },
] as IOption[];

interface ISelectCountryProps {
  onSelect: (country: PlaidEnabledCountryCode) => void;
  source: IConnectDataSourceFlowSource;
}

export const SelectCountry: React.FC<ISelectCountryProps> = (props: ISelectCountryProps) => {
  const { onSelect, source } = props;
  const { removeTopOfStack, currentStackIndex } = useStepper();
  const { trackPage } = useAnalytics();
  useEffect(() => {
    const event = CONNECT_PLAID_SOURCE_TO_PLAID_MODAL_PAGE_EVENT[source];
    if (event !== null) {
      trackPage(event);
    }
  }, [source, trackPage]);

  const [processing, setProcessing] = useState<boolean>(false);

  const { country } = useUser().vendor;

  return (
    <DataSourceLayout
      heading="Securely add your bank account"
      description={DATA_SOURCE_TEXTS.bank.description}
    >
      {processing ? (
        <Loading />
      ) : (
        <>
          <OptionsList>
            {SupportedOptions.map((option) => (
              <ListBoxItemButton
                key={option.code}
                isCenterSecondaryAction
                icon={option.icon}
                label={option.name}
                kind="secondary"
                onClick={async () => {
                  setProcessing(true);
                  onSelect(option.code);
                }}
              />
            ))}
          </OptionsList>
          <Spacer y="3" />
        </>
      )}
      <ContactSupport country={country ?? undefined} integrationType="bank" />
      {currentStackIndex > 0 && (
        <ButtonSectionWrapper>
          <DataSourceLayoutButtonSection
            secondaryButtonText="Back"
            secondaryButtonAction={removeTopOfStack}
          />
        </ButtonSectionWrapper>
      )}
    </DataSourceLayout>
  );
};

const ButtonSectionWrapper = styled.div`
  margin-top: ${t.space[5].toString()};
`;

const OptionsList = styled.div`
  overflow: auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 235px), 1fr));
  gap: ${t.space[3].toString()};
  padding-top: ${t.space[3].toString()};
`;

export const Loading: React.FC = () => (
  <LoadingSpinnerWrapper>
    <LoadingSpinner size="50px" ringWidth="4px" />
  </LoadingSpinnerWrapper>
);

const LoadingSpinnerWrapper = styled.div`
  margin-top: ${t.space[16].toString()};
  margin-bottom: ${t.space[16].toString()};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${t.space[5].toString()};
`;
